<template>

  <section class="form-card">
    <div class="form-card__intro">
      <h1>{{name}}</h1>
      <p>{{description}}</p>
    </div>
    
    <div class="form-card__actions">
      <button class="form-card__action" @click="editForm(formID)">
        <v-icon>edit</v-icon>
      </button>
      <button class="form-card__action" @click="deleteForm(formID)">
        <v-icon>delete</v-icon>
      </button>
    </div>

    <router-view></router-view>
  </section>
</template>

<script>
import axios from "axios"
export default {
  name: "FormListItem",

  props: {
    name: {
      type: String
    },
    description: {
      type: String
    },
    destination: {
      type: String
    },
    formID: {
      type: String
    }
  },

  methods: {

    editForm(id) {
      this.$router.push({ path: `/forms/:${id}` })
    },

    deleteForm(id) {
      if (confirm("Are you sure you want to delete this item.  This will remove all the associated form data")) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .delete(`${process.env.VUE_APP_BASEURL}/formlayouts/delete?formID=${id}`, { "headers": { "Authorization": jwt }})
        
        .then(response => {
          this.$emit("refresh")
        })
        .catch(e => {
          console.error("delete error", e);
        })
      })
    } else {
      console.log("NOT DELETING")
      }
    }
  }
}
</script>

<style scoped lang='scss'>

.form-card {
  // padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  // padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 80px;

  &__intro {
    flex-grow: 1;
    margin: 16px 16px 8px;
  }

  &__actions {
    background-color: #E6E8F1;
    height: 100%;
    padding: 24px 16px;
  }

  &__action {
    margin: 0 8px 0 0;

    i {
      color: #67247c;
    }  
  }
}

</style>
