<template>
  <section class="forms-management main-body">

    <div class="forms">
      <MiniHeader :heading="'Forms'" :buttonText="'Add Form'" :hasButton="true" @buttonClicked="createForm()" />

      <!-- <div class="search-row">
        <div class="item search-item">
          <label class="label">Find a form</label>
          <input class="search-box" type="text" placeholder="Find a form..."
            v-model="searchString" />
        </div>
      </div> -->

      <div class="search-result" v-if="searchString">
        <h2  v-if="searchString">Searching for: <span class="search-term">{{searchString}}</span></h2>
        <p v-if="filteredList.length === 0">No results found</p>
        <p v-else>{{filteredList.length}} results found</p>
      </div>

      <form-list-item v-for="form in filteredList" :key="form.formID" 
        :name="form.name_admin"
        :description="form.description_admin"
        :formID="form.formID"
        @refresh="getForms()" />
    
    </div>

    <div class="pagination">
      <v-pagination
        v-model="pageNumber"
        :length="pageCount"
        :total-visible="4"
        circle
        color="accent">
      </v-pagination>
    </div>

    <router-view></router-view>
  
  </section>

</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import FormListItem from "@/components/Forms/FormListItem"

export default {
  name: "FormsManagement",

  components: {
    MiniHeader,
    FormListItem,
  },

  data:() => ({
    forms: [],
    searchString: "",
    pageCount: 0,
    pageSize: 10,
    pageNumber: 1,
    formsLoaded: false
  }),

  watch: {
    pageNumber(value, oldValue) {
      console.log("Page number changed");
      if (oldValue) {
        this.formsLoaded = false
        this.getForms()
      }
    },
  },

  mounted() {
    this.getForms()
  },

  computed: {
    filteredList() {
      return this.forms.filter(form => {
        return form.name_admin.toLowerCase().includes(this.searchString.toLowerCase())
      })
    }
  },

  methods: {
    createForm() {
      console.log("Create Form Clicked")
      this.$router.push({ path: "/forms/create" })
    },

    getForms() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_BASEURL}/formlayouts?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`, { "headers": { "Authorization": jwt }})
        
        .then(response => {
          console.log("forms received", response)
          this.forms = response.data.body
          this.pageCount = Math.ceil(response.data.totalDataCount / this.pageSize);
          this.formsLoaded = true;
          this.$store.commit("completeLoading")
        })
        .catch(e => {
          console.error("Problem retrieving forms", e);
          this.$store.commit("completeLoading");
        })
      })
    },

    sortForms(sortOn) {
      console.log("Commence Sorting", sortOn)
    }
  }
}
</script>

<style scoped lang='scss'>

.search-row {
  display: flex;
  justify-content: flex-end;
}

.label {
  text-align: right;
}

.search-item {
  position: relative;
  &:before {

    content: '';
    position: absolute;
    background: transparent url('../../assets/search.svg') center no-repeat;
    right: 6px;
    top: 6px;
    height: 24px;
    width: 24px;
  }
}

.search-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  height: 36px;
  padding: 8px;
}

.search-result {
  border-bottom: 1px solid #67247C;
}

.search-term {
  color: #67247C;
}

</style>
